.card {
  position: relative;
  
  
  overflow: hidden;
  background-color: #fff; /* Default background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
}
.card:hover p, .card:hover .featured-card-h2  {
color: #fff;
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000; /* Color to show on hover, adjust opacity if needed */
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  z-index: -1; /* Ensures it is behind the card content */
}

.card:hover::before {
  transform: translateY(0);
}

.card-content {
  position: relative;
  z-index: 1; /* Ensures content is above the pseudo-element */
  padding: 20px;
}

.wrapper {
background-color: transparent;
margin-bottom: 0px;
overflow: hidden;
border-bottom: 1px solid #e2e6ea;
}
.wrapper.about {
background-color: white;
box-shadow: 2px 2px 10 0 #0000001a !important;
}
.wrapper .question-container {
width: 100%;
text-align: left;
padding: 20px 0px;
display: flex;
align-items: center;
justify-content: space-between;
font-weight: 400;
font-size: 16px;
background: transparent;
border: none;
cursor: pointer;
color: #d81220;
}
.wrapper.about .question-container {
padding: 20px 20px;
}
.question-container.active {
  
/* background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent); */
}
/* .wrapper .question-container:hover{
background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent);
} */
.wrapper .arrow {
font-size: 2rem;
transition: 0.5s ease-in-out;
}
.arrow.active {
rotate: 180deg;
color: #d81220;
}
.wrapper .answer-container {
padding: 0;
transition: height 0.4s ease-in-out;
}

.wrapper .answer-content {
padding-bottom: 10px;
font-size: 14px;
}

.all-dot {
  width: 40%;
  display: flex;
margin-top: 40px;
}


.dot {
  width: 20%;
  height: 10px;
  background: #ffffff;
 
}

.active-dot {
  background: #d81220;
}

/* src/Counter.css */

.counter {
  font-weight: bold;
  color: #d81220;
  text-align: center;
  transition: color 0.3s ease;
}

.counter.in-view {
  color: #4CAF50; /* Color change when in view */
}

.jg-border-right {
  border-right: 1px solid black;
}

@media only screen and (max-width: 960px) {
  .jg-border-right {
    border-right: 0;
  border-bottom: 1px solid black;
  }
}



.announcement-toolbar {
  width: 100%;
  background-color: #d81220;
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.announcement-text {
  display: inline-block;
  padding-left: 30%;
  animation: move-text 10s linear infinite;
  color: white;
}

@keyframes move-text {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}

.slider-container {
  width: 100%; /* Full width of the slider */
  overflow: hidden; /* Hide overflowing images */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.slider-track {
  display: flex;
  animation: scrollLeft 8s linear infinite; /* Scroll from right to left */
}
.slider-track2 {
  display: flex;
  gap: 10;
  animation: scrollLeft 8s linear infinite; /* Scroll from right to left */
  width: 100%;
}

.slider-image {
  width: 150px; /* Adjust the width as necessary */
  margin-right: 20px; /* Space between images */
  object-fit: contain; /* Ensure images fit */
}

/* Keyframes for moving images from right to left */
@keyframes scrollLeft {
  0% {
    transform: translateX(100%); /* Start from fully off-screen on the right */
  }
  100% {
    transform: translateX(-100%); /* Move fully off-screen to the left */
  }
}


